import React, { useState } from "react"
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/Button'
import 'reactjs-popup/dist/index.css'

import { Translate } from "../Translator"

const TelephonySettingsAmiba = (props) => {

    const mSettingsFixed = props.PhoneSettings["$Fixed"] === "y"
    const [mServerUrlAddress, SetServerUrlAddress] = useState(props.PhoneSettings["ServerUrlAddress"]) 
    const [mUsername, SetUsername] = useState(props.PhoneSettings["UsernamePins"].split('=')[0]) 
    const [mUserPin, SetUserPin] = useState(props.PhoneSettings["UsernamePins"].includes("=") ? props.PhoneSettings["UsernamePins"].split('=')[1] : "") 
    const [mOutboundDialPrefix, SetOutboundDialPrefix] = useState(props.PhoneSettings["OutboundDialPrefix"])  

    const UpdateSettings = () => {
        props.PhoneSettings["ServerUrlAddress"] = mServerUrlAddress
        props.PhoneSettings["UsernamePins"] = mUsername + "=" + mUserPin
        props.PhoneSettings["OutboundDialPrefix"] = mOutboundDialPrefix
        props.OnUpdatePhoneSettings()
    }    

    if (mSettingsFixed)
    {
        return (
            <span>
                <div className="smalltext">{Translate("Extension")}: {props.PhoneSettings["Extension"]}</div>
            </span>
        )
    }
    else
    {
        return (
            <span>
                <table width="100%" cellPadding={4} className="smalltext">
                <tbody>
                <tr>
                    <td>{Translate("Extension")}</td>
                    <td>{props.PhoneSettings["Extension"]}</td>
                </tr>
                <tr>
                    <td>{Translate("URL")}</td>
                    <td><input autoComplete="amiba.serverurladdress" value={mServerUrlAddress} type="text" onChange={(event) => { SetServerUrlAddress(event.target.value) }} style={{ width: "300px"}}/></td>
                </tr>
                <tr>
                    <td>{Translate("Username")}</td>
                    <td><input autoComplete="amiba.username" value={mUsername} type="text" onChange={(event) => { SetUsername(event.target.value) }} style={{ width: "300px"}}/></td>
                </tr>
                <tr>
                    <td>{Translate("User PIN")}</td>
                    <td><input autoComplete="amiba.userpin" value={mUserPin} type="text" onChange={(event) => { SetUserPin(event.target.value) }} style={{ width: "300px"}}/></td>
                </tr>
                <tr>
                    <td>{Translate("Trunk Access Code")}</td>
                    <td><input autoComplete="amiba.outbounddialprefix" value={mOutboundDialPrefix} type="text" onChange={(event) => { SetOutboundDialPrefix(event.target.value) }} style={{ width: "300px"}}/></td>
                </tr>
                </tbody>
                </table>
                {mServerUrlAddress !== props.PhoneSettings["ServerUrlAddress"] || (mUsername + "=" + mUserPin) !== props.PhoneSettings["UsernamePins"] || mOutboundDialPrefix !== props.PhoneSettings["OutboundDialPrefix"] ? <Button variant="primary" onClick={() => UpdateSettings()}>{Translate("Update settings")}</Button> : <Button variant="secondary" disabled>{Translate("Update settings")}</Button>}
            </span>
        )
    }
}

TelephonySettingsAmiba.propTypes = {
    PhoneSettings: PropTypes.object,
    OnUpdatePhoneSettings: PropTypes.func
}

export default TelephonySettingsAmiba