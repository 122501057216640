import React from "react";
import Main from "./Components/Main";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Cookies from 'js-cookie';

import { GetTheme, ThemeBackgroundColor, ThemeFontColor, ThemeBorderColor , ThemeHistoryBackgroundColor, ThemeBackgroundColorSlightlyOff, ThemeHistoryBackgroundColorHover, ThemeHistoryBackgroundColorFocus, ThemeHistoryBackgroundColorHoverFocus, ThemeHistoryFontColorFocus } from "./Components/Themes";

function App() {  
  
  let branding_signinmethod = ""
  let branding_color = "#0f9ddd"
  let branding_color_light = "#d5e6fb"
  let branding_color_dark = "#2b468b"
  let branding_productname = "CloudCTI"
  let branding_domain = ".cloudcti.nl"
  let branding_signinbaseurl = "https://signin-va.cloudcti.nl"
  if (window.location.href.startsWith("https://webclient.enreach.nl"))
  {
    branding_signinmethod = "voiceworks"
    branding_color = "#39006c"
    branding_color_light = "#ac96ff"
    branding_color_dark = "#39006c"
    branding_productname = "Enreach Connect"
    branding_domain = ".enreach.nl"
    branding_signinbaseurl = "https://signin-va.enreach.nl"
  }

  var r = document.querySelector(':root')

  //from branding
  r.style.setProperty('--brand_color', branding_color)
  r.style.setProperty('--brand_color_off', GetTheme() === "dark" ? branding_color_dark : branding_color_light)

  r.style.setProperty('--font_color', ThemeFontColor())
  r.style.setProperty('--border_color', ThemeBorderColor())
  r.style.setProperty('--scrollbar_color', ThemeBorderColor()) //tbd  
  r.style.setProperty('--background_color', ThemeBackgroundColor())
  r.style.setProperty('--background_color_slightlyoff', ThemeBackgroundColorSlightlyOff())
  r.style.setProperty('--history_background_color', ThemeHistoryBackgroundColor())
  r.style.setProperty('--history_background_color_hover', ThemeHistoryBackgroundColorHover())
  r.style.setProperty('--history_background_color_focus', ThemeHistoryBackgroundColorFocus())
  r.style.setProperty('--history_background_color_hoverfocus', ThemeHistoryBackgroundColorHoverFocus())
  r.style.setProperty('--history_font_color_focus', ThemeHistoryFontColorFocus())

  function AuthRoute({ children, ...rest }) 
  { 
    let securityToken = null
    try
    {
      //debug
      //return (<Route {...rest} render={({ location }) => children } />);

      const cookie = Cookies.get("webclient")
      if (cookie)
      {
        console.log(cookie)
        securityToken = cookie
      }
    }
    catch { }

    if (securityToken)
    {
      return (<Route {...rest} render={({ location }) => children }/>);
    }
    else
    {   
      const redirecturl = window.location.href.replaceAll(":","%3A").replaceAll("/","%2F")  
      window.location.href = branding_signinbaseurl + "/signin/onelogin?clientid=webclient&redirecturl=" + redirecturl + (branding_signinmethod ? "&signinmethod=" + branding_signinmethod : "")
      return null;
    }
  }
  
  const page = window.location.pathname.toLowerCase().trim("/")   
  const embedded = (page.endsWith("salesforce") ? "salesforce" : "") //tbd: more

  return (
    <Router>
        <Switch>
          <AuthRoute>
            <Main ProductName={branding_productname} Domain={branding_domain} Embedded={embedded}/>
          </AuthRoute>
        </Switch>
    </Router>
  );
}


export default App;
