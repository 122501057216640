import React, { useEffect, useRef, useState } from "react"
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/Button'
import Cookies from 'js-cookie'
import { jwtDecode } from 'jwt-decode'
import { useHistory } from "react-router-dom"
import { HubConnectionBuilder } from "@microsoft/signalr"
import packageJson from '../../package.json'

import { GetLanguageId, SetLanguageId, ResetLanguageId, Translate } from "./Translator"
import { LookupApiCallHistoryRegister, LookupApiGetCallHistory, LookupApiCallHistoryAddOutboundCall, LookupApiCallHistoryDeleteCall, LookupApiGetSearchHistory, LookupApiUpdateSearchHistory, LookupApiGetSpeedDials, LookupApiUpdateSpeedDials, LookupApiClientReplicatorInfo, LookupApiLookupNumber } from "../ExternalApi/LookupApi"
import { PhoneApiGetPhoneSettings, PhoneApiSetPhoneSettings, PhoneApiSubscribe, PhoneApiMakeCall } from "../ExternalApi/PhoneApi"
import { PushApiSubscribe, PushApiUnsubscribe, PushApiHasSubscription } from "../ExternalApi/PushApi"

import { IsEmptyString, UrlBase64ToUint8Array } from "./GeneralFunctions"

import CallNotification from "./CallNotification"
import UserSettingsModal from "./UserSettingsModal"
import CallHistory from "./CallHistory"
import SearchArea from "./SearchArea"
import SpeedDials from "./SpeedDials"

import CloudCTIPng from '../Assets/CloudCTI.png'
import SettingsUserPng from '../Assets/settings_user.png'
import SignOutPng from '../Assets/signout.png'

import "../css/Main.css"

//Subclasses

var sforce

const Main = (props) => {

  const mRef = React.createRef()
  
  //Push
  const cPushPublicVapidKey = "BJovlKvWRuqeCSbyyogJUuJZ3iOPlwF1JagAADr3E7zqtNb-c-oKm1MB3eptSuOuoNWlVfkBDgiPriKBU0N7ifI"

  const mHistory = useHistory();
  const [mRepainter, SetRepainter] = useState(false)

  //const mSecurityToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJTaWduaW5NZXRob2QiOiJDbG91ZENUSSIsIlNpZ25pblVzZXIiOiJhbmRyZW1ldGFzd2l0Y2hAY2xvdWRjdGkubmwiLCJTaWduaW5QYXNzd29yZCI6Im0wQkFtUVF3SkRuVHRrU2xoR2VmWkYvRTdEREtBQ1JPRWpnM2E2UWlVVUZrMXJrdEtqMm85NlNNOFAzUTYzdUg4MU9vTW92bTZGWlV0cFZxUlhucUhDK091TExwZXhXV2U4cnMyZmMwbzRzUG1VQXo3ejc0MGVjYUpoajNwUGRuIiwiU2lnbmluQXBwbGljYXRpb24iOiJXZWJDbGllbnQyMDE4IiwiVGVuYW50R3VpZCI6IlRlbmFudF80M2Y0YjUzMy1mOTg3LTQzODAtYmNhZi00MGNkNzYxMGFmYmIiLCJUZW5hbnROYW1lIjoiQ2xvdWRDVEkgSFEiLCJVc2VyR3VpZCI6IlRlbmFudFVzZXJfYTc0YTRiODUtOTAyNS00NDUxLTk3NWYtMDYyYzI1NjBkMWQ1IiwiVXNlck5hbWUiOiJBbmRyw6kiLCJVc2VySXNBZG1pbiI6IjEiLCJVc2VySXNUZW5hbnRBZG1pbiI6IjAiLCJFbWFpbCI6ImFuZHJlbWV0YXN3aXRjaEBjbG91ZGN0aS5ubCIsIkxpY2VuY2VOYW1lIjoiQW5kcmUgTGljZW50aWUgU1AiLCJMaWNlbmNlUGxhdGZvcm1zIjoiTWV0YXN3aXRjaCIsIlJlc2VsbGVyT3duZXJHdWlkIjoiUmVzZWxsZXJfMDk3RkUwOUEtNEI2Ni00QTdDLUE2RjctODFGNUJGMzJFMTdCIiwiUmVjb2duaXRpb25BbmRTY3JlZW5wb3AiOiIxIiwiTWV0YVN3aXRjaEJhc2VVcmkiOiJodHRwczovL3VjY29tbXBvcnRhbC5jb20iLCJNZXRhU3dpdGNoRGlyZWN0b3J5TnVtYmVyIjoiNDgwMzYyMzE0OCIsIk1ldGFTd2l0Y2hQYXNzd29yZCI6IjZ1NVdyV0trZ21TTU5ZUTUvSGg2cmptSzNtSHdhK3Z4NisvYUsxQzdabzkwNXZwRjVERVlUWm5mck9XUkgveVVVOEg1RnJUVkMxMWpmakt6OUNNNVRlZ1JJT2dmQkFFY3R5MUQ3c1VzUU52bkFMUC94UTJQeDI4eUlaSXErRk9xIiwiUm9sZXMiOiJVc2VyLENhbGxDb250cm9sLE1ldGFzd2l0Y2giLCJleHAiOjE3MzUyMDg5NTYsImlzcyI6InNpZ25pbi12YSIsImF1ZCI6IkNsb3VkQ1RJMjAyMiJ9.c1TVLdrwvTeEyOMbQ3u8Gp1pt9kJXZGVhBjIpQeoWEs"
  const mSecurityToken = Cookies.get("webclient")
  const [mSecurityTokenData, SetSecurityTokenData] = useState({})

  const [mVisualMode, SetVisualMode] = useState("FullMode")
  const [mMobileModeWindow, SetMobileModeWindow] = useState(localStorage.getItem("MobileModeWindow") || "Search")

  const [mSearchHistory, SetSearchHistory] = useState([])
  const [mCallHistory, SetCallHistory] = useState([])
  const [mSpeedDials, SetSpeedDials] = useState([])

  const [mPhoneApiError, SetPhoneApiError] = useState(null)

  const [mPhoneSettings, SetPhoneSettings] = useState(null)
  const [mPhoneSettingsTemplate, SetPhoneSettingsTemplate] = useState("")

  const [mLastCall, SetLastCall] = useState(null)
  const [mLastCallLookupResultIndex, SetLastCallLookupResultIndex] = useState(0)

  const [mIntegrations, SetIntegrations] = useState([])
  const [mCallfieldScripts, SetCallfieldScripts] = useState([])

  const [mShowModalUserSettings, SetShowModalUserSettings] = useState(false)

  const [mSignalRConnection, SetSignalRConnection] = useState(new HubConnectionBuilder().withUrl("https://eventhub7cloudcti.azurewebsites.net/eventhub/api").withAutomaticReconnect().build()); 

  const [mEmbeddedScriptInserted, SetEmbeddedScriptInserted] = useState(false) 
  const [mEmbeddedScriptLoaded, SetEmbeddedScriptLoaded] = useState(false) 

  useEffect(() => {

    const handleResize = () => {
      SetVisualMode(GetVisualMode())
    }

    const DecodedToken = jwtDecode(mSecurityToken)
    SetSecurityTokenData({ 
      Email : DecodedToken.Email,
      UserName : DecodedToken.UserName,
      TenantName : DecodedToken.TenantName,
      LicenceName : DecodedToken.LicenceName,
      LicencePlatforms : DecodedToken.LicencePlatforms,
      UserIsAdmin : DecodedToken.UserIsAdmin,
      UserHasRecognitionAndScreenpopLicense : DecodedToken.RecognitionAndScreenpop === "1"}
    )

    if (mSignalRConnection) {
      mSignalRConnection
        .start()
        .then(() => {
          mSignalRConnection.onclose(() => { OnSignalRError("Connection closed"); })
        })
        .then(() => {
          mSignalRConnection.onreconnected(() => { mSignalRConnection.invoke("Connect",DecodedToken.Email) })
        })
        .then(() => {
          mSignalRConnection.on("ReceiveMessage", (message) => {
            OnSignalREvent(message);
          });
        })
        .then(() => {
          mSignalRConnection.invoke("Connect",DecodedToken.Email)
        })
        .catch((error) => OnSignalRError(error));
    }

    LookupApiClientReplicatorInfo(mSecurityToken).then((clientreplicatorInfo) => { SetIntegrations(clientreplicatorInfo.data.ReplicatorNames); SetCallfieldScripts(clientreplicatorInfo.data.CallFieldScriptNames) })
   
    LookupApiCallHistoryRegister(mSecurityToken).then(() => LookupApiGetCallHistory(mSecurityToken)).then((callHistory) => { SetCallHistory(callHistory)})
    LookupApiGetSearchHistory(mSecurityToken).then((searchHistory) => { SetSearchHistory(searchHistory)})
    LookupApiGetSpeedDials(mSecurityToken).then((speedDials) => { SetSpeedDials(speedDials)})    

    PhoneApiSubscribe(mSecurityToken).then((result) => ProcessPhoneApiSubscribeResult(result))    
    
    PushApiHasSubscription(mSecurityToken).then((settings) => ProcessPushApiHasSubscriptionResult(mSecurityToken))

    ResetLanguageId()

    SetVisualMode(GetVisualMode())

    if (props.Embedded !== "")
    {   
      InsertEmbeddedSalesforce()
    }

    window.addEventListener('resize', handleResize);
    return () => { 
      window.removeEventListener('resize', handleResize)
      document.body.removeChild(script)
    }
  }, []); 

  const InsertEmbeddedSalesforce = () => {

    const script = document.createElement('script')
    script.src = props.Embedded === "salesforce" ? "../Embedded/Salesforce/opencti_min.js" : ""
    script.type = "text/jsx"
    script.async = true
    //document.body.appendChild(script)
    //document.getElementsByTagName('head')[0].insertBefore(node, document.getElementsByTagName('script')[0])
    document.body.appendChild(script)
    SetEmbeddedScriptInserted(true)
  }

  const LoadEmbeddedSalesforce = () => {
    try 
    {
      let onClickToDialListener = (payload) => 
      {
        try 
        {
          console.log('Salesforce clicked to dial: ' + JSON.stringify(payload))
        }
        catch
        {
          console.log("Failed to execute the makecall request.");
        }
      }
  
      console.log("sforce")
      console.log(sforce)
      sforce.opencti.onClickToDial({ listener: onClickToDialListener }).then(SetEmbeddedScriptAdded(true))
    }
    catch (e)
    {
      console.log("Unable to set onClickToDial listener: " + e)
    }
  } 


  const AddToSearchHistory = (aNumber, aName, aApplication, aScripts) => {
    try 
    {  
      const ExistingItems = props.SearchHistory
        .filter((item,index) => (item.Number !== aNumber))
        .filter((item,index) => (index < 9)) 
      const SearchHistory = [{ Number: aNumber, Name:aName, Application:aApplication, Scripts:aScripts},...ExistingItems]
      LookupApiUpdateSearchHistory(props.SecurityToken, SearchHistory)
        .then(() => LookupApiGetSearchHistory(mSecurityToken))
        .then((searchHistory) => { SetSearchHistory(searchHistory)})
    } 
    catch (ex) 
    {    
      console.log("AddToLastSearches failed")
      console.log(ex)
    }
  }

  const AddToSpeedDial = async (aNumber, aName, aApplication, aScripts) => 
  {
    try 
    {  
      let SpeedDials = (mSpeedDials === undefined) || (mSpeedDials === null) ? [] : mSpeedDials
      SpeedDials = [...SpeedDials,...[{ Number: aNumber, Name:aName, Application:aApplication, Scripts:aScripts}]]
      LookupApiUpdateSpeedDials(mSecurityToken, SpeedDials)
        .then(() => LookupApiGetSpeedDials(mSecurityToken))
        .then((speedDials) => { SetSpeedDials(speedDials)})
    } 
    catch (ex) 
    {    
      let error = ex;
      console.log("AddToSpeedDial failed: " + error);
    }
  }

  const ApplyPhoneSettings = (data) => {
    SetPhoneSettingsTemplate(data.SettingsTemplate)
    SetPhoneSettings(data.PhoneSettings)
  }

  const ChangeLanguageId = (languageid) => {
    SetLanguageId(languageid)
    SetRepainter(!mRepainter)
  }

  const DisableBrowserNotifications = () =>
  {
    PushApiUnsubscribe(mSecurityToken)
  }
  
  const EnableBrowserNotifications = async () =>
  {
    const result = await Notification.requestPermission();
    if (result === 'denied') 
    {
      console.error('The user explicitly denied the permission request.');
      return;
    }
    else if (result === 'granted') 
    {
      console.info('The user accepted the permission request.');
    }

    navigator.serviceWorker.getRegistrations().then((registrations) => {
      const registration = registrations[0]
      console.log(registration)
      registration.pushManager.getSubscription().then((subscribed) => {
        if (subscribed) {
          console.info('User is already subscribed.');
          return;
        }
        registration.pushManager.subscribe({ userVisibleOnly: true, applicationServerKey: UrlBase64ToUint8Array(cPushPublicVapidKey) })
          .then((subscription) => {
            console.log('Service worker subscribe succeeded:', subscription)
            const key = subscription.getKey("p256dh")
            const encodedKey = key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null;
            const auth = subscription.getKey("auth")
            const encodedAuth = auth ? btoa(String.fromCharCode.apply(null, new Uint8Array(auth))) : null;
            PushApiSubscribe(mSecurityToken, "", subscription.endpoint, encodedKey, encodedAuth)    
          })
      })
    })
  }

  const GetVisualMode = () => {
    return (window.innerWidth < 740) ? "MobileMode" : (window.innerWidth < 930 ? "CompactModeNoHistorySearch" : (window.innerWidth < 1260 ? "CompactMode" : "FullMode"))
  }

  const MakeCall = (aNumber, aName, aApplication, aScripts) => {
    try 
    {  
      PhoneApiMakeCall(mSecurityToken, aNumber)
      
      LookupApiCallHistoryAddOutboundCall(mSecurityToken, aNumber, aName, aApplication, aScripts, false)
        .then((callHistory) => { SetCallHistory(callHistory)})
    } 
    catch (ex) 
    {    
      console.log("MakeCall failed")
      console.log(ex)
    }
  }  

  const MobileModeWindowChange = (aWindow) =>
    {
      SetMobileModeWindow(aWindow)
      localStorage.setItem("MobileModeWindow",aWindow)
  }

  const OpenWebConfigurator = () => 
  {
    window.open("https://webconfigurator.cloudcti.nl?handover_token=" + mSecurityToken)
  } 

  const ProcessPhoneApiSubscribeResult = (result) => {
    SetRepainter(!mRepainter)
    if ((result.status !== undefined) && (result.status === 200))
    {
      SetPhoneApiError("")
    }
    else
    {
      let Error = result.message
      if (result.response && result.response.data && result.response.data.Exception && result.response.data.Exception.Message)
      {        
        try { Error = result.response.data.Exception.Message } catch {}
      }
      else if (result.response && !IsEmptyString(result.response.data))
      {
        try { Error = result.response.data } catch {}
      }
      SetPhoneApiError(Error)
    }

    PhoneApiGetPhoneSettings(mSecurityToken).then((result) => ProcessPhoneApiGetSettingsResult(result)) 
  }

  const ProcessPhoneApiGetSettingsResult = (result) => {
    if ((result.status !== undefined) && (result.status === 200))
    {
      //console.log("ProcessPhoneApiGetSettingsResult ApplyPhoneSettings")
      //console.log(result.data)
      ApplyPhoneSettings(result.data)
    }
    else
    {
      let Error = result.message
      if (result.response && result.response.data && result.response.data.Exception && result.response.data.Exception.Message)
      {        
        try { Error = result.response.data.Exception.Message } catch {}
      }
      else if (result.response && !IsEmptyString(result.response.data))
      {
        try { Error = result.response.data } catch {}
      }
      SetPhoneApiError(Error)
    }
  }

  const ProcessPushApiHasSubscriptionResult = (result) => {
    if ((result.status !== undefined) && (result.status === 204))
    {   
      EnableBrowserNotifications()
    }
  }

  const ProcessPhoneApiSetSettingsResult = (result) => {
    if ((result.status !== undefined) && (result.status === 200))
    {
      PhoneApiSubscribe(mSecurityToken).then((subresult) => ProcessPhoneApiSubscribeResult(subresult))
    }
    else
    {
      let Error = result.message
      if (result.response && result.response.data && result.response.data.Exception && result.response.data.Exception.Message)
      {        
        try { Error = result.response.data.Exception.Message } catch {}
      }
      else if (result.response && !IsEmptyString(result.response.data))
      {
        try { Error = result.response.data } catch {}
      }
      SetPhoneApiError(Error)
    }
    
  }
  
  const RemoveFromCallHistory = async (aId) => 
  {
    try 
    {  
        LookupApiCallHistoryDeleteCall(mSecurityToken, aId)
          .then((callHistory) => { SetCallHistory(callHistory)})
    } 
    catch (ex) 
    {    
        let error = ex;
        console.log("RemoveFromCallHistory failed: " + error);
    }    
  }
    
  const RemoveFromSpeedDial = async (aNumber) => 
  {
    try 
    {  
      const SpeedDials = mSpeedDials.filter((item,index) => item.Number !== aNumber)
      LookupApiUpdateSpeedDials(mSecurityToken, SpeedDials)
        .then(() => LookupApiGetSpeedDials(mSecurityToken))
        .then((speedDials) => { SetSpeedDials(speedDials)})
    } 
    catch (ex) 
    {    
      let error = ex;
      console.log("RemoveFromSpeedDial failed: " + error);
    }
  } 

  const SignOut = () => {
    Cookies.remove('webclient', { domain: props.Domain })
    mHistory.push(packageJson.homepage)
  }  

  const OnSignalRError = (error) => {
    console.log("SignalR eror: " + error)
  }  

  const OnSignalREvent = async (event) => {
    let CallEvent = JSON.parse(event)
    let Recognitions = []
    let CallfieldScripts = []

    if (CallEvent.CallId)
    {
      try
      {
        const result = await LookupApiLookupNumber(mSecurityToken, CallEvent.Direction.toLowerCase() === "inbound" ? CallEvent.CallerNumber : CallEvent.CalledNumber)

        result.data.Matches.map((match) => 
        {
          if (match.IntegrationGuid)
          {
            Recognitions.push(match)
          }
          else
          {
            CallfieldScripts = match.Scripts
          }
        })
      }
      catch { }
      CallEvent.Recognitions = Recognitions
      CallEvent.CallfieldScripts = CallfieldScripts

      console.log(JSON.stringify(CallEvent))

      SetLastCallLookupResultIndex(0)
      SetLastCall(CallEvent)
    }
  } 

  const UpdatePhoneSettings = () => {
    PhoneApiSetPhoneSettings(mSecurityToken,mPhoneSettings).then((result) => ProcessPhoneApiSetSettingsResult(result))  
  }

  //----> HTML

  const ReturnEmbeddedSalesforceMode = () => {
    return <table width="100%" height="100%">        
    <tbody>
    <tr height="*">
      <td colSpan="3" valign="top">
        
        <table width="100%" height="100%" cellPadding="0" cellSpacing="0">
        <tbody>
          <tr height="30">
            <td valign="center" align="left" className="padded10">
              <div className={!IsEmptyString(mPhoneApiError) ? "notification notificationerror" : "notification"}>
                  {!IsEmptyString(mPhoneApiError) ? <div>{Translate("Failed to subscribe for phone events")}</div> : <span/>}
                  {!IsEmptyString(mPhoneApiError) ? Translate("Error") + ": " + mPhoneApiError : 
                          (mLastCall === null ? Translate("No call information available") : <CallNotification Call={mLastCall} LookupResultIndex={mLastCallLookupResultIndex} />)
                  }
              </div>  
            </td>
          </tr>   
          </tbody>
        </table>

      </td>
    </tr>
    </tbody>
    </table>
  }

  const ReturnMobileMode = () => {
    return <table width="100%" height="100%">        
    <tbody>
    <tr height="24">
        <td width="33%" className={mMobileModeWindow === "Search" ? "mobilemodewindow mobilemodewindowselected" : "mobilemodewindow"} onClick={() => MobileModeWindowChange("Search")}><span className="up3px">{Translate("Search").toUpperCase()}</span></td>
        <td width="34%" className={mMobileModeWindow === "History" ? "mobilemodewindow mobilemodewindowselected" : "mobilemodewindow"} onClick={() => MobileModeWindowChange("History")}><span className="up3px">{Translate("History").toUpperCase()}</span></td>
        <td width="33%" className={mMobileModeWindow === "Speed dial" ? "mobilemodewindow mobilemodewindowselected" : "mobilemodewindow"} onClick={() => MobileModeWindowChange("Speed dial")}><span className="up3px">{Translate("Speed dial").toUpperCase()}</span></td>
    </tr>
    <tr height="*">

        {mMobileModeWindow === "Search" ? 

            <td colSpan="3" valign="top">
              
              <table width="100%" height="100%" cellPadding="0" cellSpacing="0">
              <tbody>
                <tr height="30">
                  <td valign="center" align="left" className="padded10">
                    <div className={!IsEmptyString(mPhoneApiError) ? "notification notificationerror" : "notification"}>
                        {!IsEmptyString(mPhoneApiError) ? <div>{Translate("Failed to subscribe for phone events")}</div> : <span/>}
                        {!IsEmptyString(mPhoneApiError) ? Translate("Error") + ": " + mPhoneApiError : 
                                (mLastCall === null ? Translate("No call information available") : <CallNotification Call={mLastCall} LookupResultIndex={mLastCallLookupResultIndex} />)
                        }
                    </div>  
                  </td>
                </tr>   
                <tr height="*">
                  <td valign="top" align="left">
                    <div className="scrollbary">
                        <SearchArea 
                          SecurityToken={mSecurityToken}
                          SearchHistory={mSearchHistory}
                          OnMakeCall={(number, name, application, scripts) => MakeCall(number, name, application, scripts)}
                          OnAddToSearchHistory={(number, name, application, scripts) => AddToSearchHistory(number, name, application, scripts)}
                        />
                    </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>

            : (mMobileModeWindow === "History" ? 

            <td colSpan="3" align="left" valign="top">
              <CallHistory 
                Calls={mCallHistory} 
                SpeedDials={((mSpeedDials === undefined) || (mSpeedDials === null) || !mSpeedDials) ? [] : mSpeedDials} 
                ShowHeader={false} 
                AddSearch={true}
                OnMakeCall={(number, name, application, scripts) => MakeCall(number, name, application, scripts)} 
                OnAddToSpeedDial={(number, name, application, scripts) => AddToSpeedDial(number, name, application, scripts)}
                OnRemoveFromSpeedDial={(number) => RemoveFromSpeedDial(number)} 
                OnRemoveFromCallHistory={(id) => RemoveFromCallHistory(id)}
              />
            </td>

            :

            <td colSpan="3" align="left" valign="top">                      
              <SpeedDials 
                SpeedDials={((mSpeedDials === undefined) || (mSpeedDials === null) || !mSpeedDials) ? [] : mSpeedDials} 
                ShowHeader={false} 
                OnMakeCall={(number, name, application, scripts) => MakeCall(number, name, application, scripts)} 
                RemoveFromSpeedDialEvent={(number) => RemoveFromSpeedDial(number)}
              />
            </td>)
          }
    </tr>
    </tbody>
    </table>
  }

  const ReturnCompactMode = (aAddHistorySearch) => {
    return <table width="100%" height="100%" className="main">        
    <tbody>
    <tr>
        <td width="366" valign="top">

        <table width="100%" height="100%" cellPadding="0" cellSpacing="0">
          <tbody>
            <tr height="30">
              <td valign="center" align="left" className="padded10">
                <div className={!IsEmptyString(mPhoneApiError) ? "notification notificationerror" : "notification"}>
                    {!IsEmptyString(mPhoneApiError) ? <div>{Translate("Failed to subscribe for phone events")}</div> : <span/>}
                    {!IsEmptyString(mPhoneApiError) ? Translate("Error") + ": " + mPhoneApiError : 
                            (mLastCall === null ? Translate("No call information available") : <CallNotification Call={mLastCall} LookupResultIndex={mLastCallLookupResultIndex} />)
                    }
                </div>  
              </td>
            </tr>   
            <tr height="*">
              <td valign="top" align="left">
                <SearchArea 
                  SecurityToken={mSecurityToken}
                  SearchHistory={mSearchHistory}
                  OnMakeCall={(number, name, application, scripts, addtohistory, addtolastsearches) => MakeCall(number, name, application, scripts, addtohistory, addtolastsearches)}
                />
              </td>
            </tr>
          </tbody>
        </table>

        </td>
        <td width="*" valign="top" className="mainborderleft mainborderright">
          <SpeedDials 
            SpeedDials={((mSpeedDials === undefined) || (mSpeedDials === null) || !mSpeedDials) ? [] : mSpeedDials} 
            ShowHeader={true} 
            OnMakeCall={(number, name, application, scripts) => MakeCall(number, name, application, scripts, true, false)} 
            RemoveFromSpeedDialEvent={(number) => RemoveFromSpeedDial(number)}
          />
          <CallHistory 
            Calls={mCallHistory} 
            SpeedDials={((mSpeedDials === undefined) || (mSpeedDials === null) || !mSpeedDials) ? [] : mSpeedDials} 
            ShowHeader={true} 
            AddSearch={aAddHistorySearch}
            OnMakeCall={(number) => MakeCall(number)} 
            OnAddToSpeedDial={(number, name, application, scripts) => AddToSpeedDial(number, name, application, scripts)}
            OnRemoveFromSpeedDial={(number) => RemoveFromSpeedDial(number)} 
            OnRemoveFromCallHistory={(id) => RemoveFromCallHistory(id)}
          />
        </td>
    </tr>
    </tbody>
    </table>    
  }

  const ReturnFullMode = () => {
    return <table width="100%" height="100%">        
      <tbody>
      <tr>        
          <td width="366" valign="top">

          <table width="100%" height="100%" cellPadding="0" cellSpacing="0">
            <tbody>
              <tr height="30">
                <td valign="center" align="left" className="padded10">
                  <div className={!IsEmptyString(mPhoneApiError) ? "notification notificationerror" : "notification"}>
                      {!IsEmptyString(mPhoneApiError) ? <div>{Translate("Failed to subscribe for phone events")}</div> : <span/>}
                      {!IsEmptyString(mPhoneApiError) ? Translate("Error") + ": " + mPhoneApiError : 
                        (mLastCall === null ? Translate("No call information available") : <CallNotification Call={mLastCall} LookupResultIndex={mLastCallLookupResultIndex} />)
                      }
                  </div>  
                </td>
              </tr>   
              <tr height="*">
                <td valign="top" align="left">
                  <SearchArea 
                    SecurityToken={mSecurityToken}
                    SearchHistory={mSearchHistory}
                    OnMakeCall={(number, name, application, scripts, addtohistory, addtolastsearches) => MakeCall(number, name, application, scripts, addtohistory, addtolastsearches)}
                  />
                </td>
              </tr>
            </tbody>
          </table>

          </td>
          <td width="*" valign="top" className="mainborderleft mainborderright">
            <CallHistory 
              Calls={mCallHistory} 
              SpeedDials={((mSpeedDials === undefined) || (mSpeedDials === null) || !mSpeedDials) ? [] : mSpeedDials} 
              ShowHeader={true} 
              AddSearch={true}
              OnMakeCall={(number) => MakeCall(number)} 
              OnAddToSpeedDial={(number, name, application, scripts) => AddToSpeedDial(number, name, application, scripts)}
              OnRemoveFromSpeedDial={(number) => RemoveFromSpeedDial(number)} 
              OnRemoveFromCallHistory={(id) => RemoveFromCallHistory(id)}
            />
          </td>
          <td width="339" valign="top">
            <SpeedDials 
              SpeedDials={((mSpeedDials === undefined) || (mSpeedDials === null) || !mSpeedDials) ? [] : mSpeedDials} 
              ShowHeader={true} 
              OnMakeCall={(number, name, application, scripts) => MakeCall(number, name, application, scripts, true, false)} 
              OnRemoveFromSpeedDial={(number) => RemoveFromSpeedDial(number)}
            />
          </td>
      </tr>
      </tbody>
      </table>   
  }

  if (mEmbeddedScriptInserted && !mEmbeddedScriptLoaded)
  {
    LoadEmbeddedSalesforce()
    SetEmbeddedScriptLoaded(true)
  }

  return (
    <span className="main">

      <table className="main" height="100%" width="100%" cellPadding={0}>        
      <tbody>
        <tr height="0">
          <td width="100"/>
          <td width="*"/>
          <td width="100"/>
        </tr>
        <tr height="25" className="topbar"> 
            <td/>
            <td align="center" valign="center">              
                <img src={CloudCTIPng} className="cloudctiicon"/>
            </td>          
            <td align="right" valign="center">           
                <Button variant="outline-info" className="primarybutton link" title="Settings" onClick={() => SetShowModalUserSettings(true)}><img src={SettingsUserPng}/></Button>
                <Button variant="outline-info" className="primarybutton link" title="Sign out" onClick={() => SignOut()}><img src={SignOutPng}/></Button>
                &nbsp;
            </td>   
        </tr>
        <tr height="*"> 
            <td colSpan="3" width="*" align="center" valign="top">

              {props.Embedded === "salesforce" ? ReturnEmbeddedSalesforceMode() : (mVisualMode == "MobileMode" ? ReturnMobileMode() : (mVisualMode == "CompactModeNoHistorySearch" ? ReturnCompactMode(false) : (mVisualMode == "CompactMode" ? ReturnCompactMode(true) : ReturnFullMode())))}

            </td>
        </tr>
        </tbody>
      </table>

      {mShowModalUserSettings ? <UserSettingsModal Email={mSecurityTokenData.Email} UserName={mSecurityTokenData.UserName} TenantName={mSecurityTokenData.TenantName} LicenceName={mSecurityTokenData.LicenceName} LicencePlatforms={mSecurityTokenData.LicencePlatforms} LicenceRecognitionAndScreenpop={mSecurityTokenData.UserHasRecognitionAndScreenpopLicense} LanguageId={GetLanguageId()} Integrations={mIntegrations} CallFieldScripts={mCallfieldScripts} UserIsAdmin={mSecurityTokenData.UserIsAdmin ? true : false} PhoneSettingsTemplate={mPhoneSettingsTemplate} PhoneSettings={mPhoneSettings} PhoneApiError={mPhoneApiError} OnHide={() => SetShowModalUserSettings(false)} OnDisableBrowserNotifications={DisableBrowserNotifications} OnEnableBrowserNotifications={EnableBrowserNotifications} OnUpdatePhoneSettings={() => UpdatePhoneSettings()} OnUpdateLanguage={ChangeLanguageId} OnTranslate={Translate} OnOpenWebConfigurator={() => OpenWebConfigurator()}/> : <span/>}
    
    </span>
  ); 
}

Main.propTypes = {
  ProductName: PropTypes.string,
  Domain: PropTypes.string,
  Embedded: PropTypes.string
}

export default Main;