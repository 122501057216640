import axios from "axios"
//import { ServiceWorkerModule } from "service-worker";

const cPushBaseApi = "https://push-va.cloudcti.nl/push/"

export async function PushApiSubscribe(aSecurityToken, aBrandNotificationLogo, aEndPoint, aP256dh, aAuth)
{
    return axios.post(cPushBaseApi + "push/settings",  
    {
        Settings: {
            Endpoint: aEndPoint,
            P256dh: aP256dh,
            Auth: aAuth,
        },
        BrandNotificationLogo: aBrandNotificationLogo
    },
    {
        headers: {
            Authorization: "Bearer " + aSecurityToken,
            Accept: "application/json"
        }
    })    
}

export async function PushApiUnsubscribe(aSecurityToken) 
{
    return axios.delete(cPushBaseApi + "push/settings",  
    {
        headers: {
            Authorization: "Bearer " + aSecurityToken,
            Accept: "application/json"
        }
    })     
}

export async function PushApiHasSubscription(aSecurityToken) 
{
    return axios.get(cPushBaseApi + "push/settings",  
    {
        headers: {
            Authorization: "Bearer " + aSecurityToken,
            Accept: "application/json"
        }
    })     
}