export function TranslationsNederlands() 
{
    return [  
        {"id":"Add to speed dial","value":"Aan snelkeuze toevoegen"},
        {"id":"All","value":"Alles"},
        {"id":"An error has occurred while searching","value":"Er is een fout opgetreden tijdens het zoeken"},  
        {"id":"Analog phone","value":"Analoge telefoon"},        
        {"id":"API token","value":"API token"},            
        {"id":"Asterisk serial","value":"Asterisk serial"},    
        {"id":"Auto Answer","value":"Automatisch beantwoorden"},         
        {"id":"Browser notifications","value":"Browser notificaties"},  
        {"id":"Call","value":"Bellen"},       
        {"id":"Call back","value":"Terugbellen"},
        {"id":"Caller recognition and scripts","value":"Beller herkenning en scripts"},
        {"id":"Click-to-call","value":"Click-to-call"},
        {"id":"Configure your CRM","value":"Configureer uw CRM"},  
        {"id":"Configure user settings","value":"Configureer gebruikersinstellingen"},        
        {"id":"Copy number","value":"Kopieer nummer"},      
        {"id":"Country Code","value":"Landcode"}, 
        {"id":"CRMs and scripts","value":"CRMs en scripts"},        
        {"id":"Desktop phone","value":"Desktop telefoon"},  
        {"id":"Device","value":"Toestel"},
        {"id":"Dialer URL Protocol","value":"Uitbel URL Protocol"},
        {"id":"Disable","value":"Uitschakelen"},
        {"id":"Enable","value":"Inschakelen"},
        {"id":"Error","value":"Fout"}, 
        {"id":"Extension","value":"Extensie"},       
        {"id":"External phone/PBX","value":"Externe telefoon/PBX"},        
        {"id":"Failed to subscribe for phone events","value":"Kan niet abonneren op telefonie events"},
        {"id":"History","value":"Geschiedenis"},
        {"id":"Host URL","value":"Host URL"},
        {"id":"Incoming","value":"Inkomend"},   
        {"id":"Incoming call","value":"Inkomend gesprek"},  
        {"id":"Language","value":"Taal"},
        {"id":"License","value":"Licentie"},
        {"id":"Mobile phone","value":"Mobiele telefoon"},  
        {"id":"More results found. Refine your search","value":"Meer resultaten gevonden. Verfijn uw zoekopdracht"},   
        {"id":"No","value":"Nee"}, 
        {"id":"No call information available","value":"Geen gespreksinformatie beschikbaar"},   
        {"id":"No results found","value":"Geen resultaten gevonden"},   
        {"id":"Number","value":"Nummer"},
        {"id":"off","value":"uit"},
        {"id":"on","value":"aan"},
        {"id":"Outgoing","value":"Uitgaand"}, 
        {"id":"Password","value":"Wachtwoord"},  
        {"id":"Phone type","value":"Telefoon type"},      
        {"id":"Remove from speed dial","value":"Verwijderen uit snelkeuze"},
        {"id":"Remove from view","value":"Verwijderen uit weegave"},  
        {"id":"Sandbox account","value":"Zoeken"}, 
        {"id":"Search field must contain at least 3 characters","value":"Zoekveld moet ten minste 3 karakters bevatten"}, 
        {"id":"Searching","value":"Bezig met zoeken"},
        {"id":"Sign out","value":"Afmelden"},
        {"id":"Signed in as","value":"Aangemeld als"},
        {"id":"Sip Account","value":"Sip Account"},
        {"id":"Softphone","value":"Softphone"},  
        {"id":"Speed dial","value":"Snelkeuze"},    
        {"id":"Telephony","value":"Telefonie"},
        {"id":"Telephony platform","value":"Telefonie platform"},
        {"id":"Theme","value":"Thema"},
        {"id":"Time","value":"Tijd"},    
        {"id":"Trunk Access Code","value":"Toegangscode Buitenlijn"}, 
        {"id":"Type a name","value":"Typ een naam"}, 
        {"id":"Unknown contact","value":"Onbekend contact"},    
        {"id":"Update settings","value":"Update instellingen"},  
        {"id":"URL","value":"URL"},    
        {"id":"User ID","value":"Gebruiker ID"},    
        {"id":"User PIN","value":"Gebruiker PIN"},    
        {"id":"User settings","value":"Gebruikersinstellingen"},
        {"id":"Username","value":"Gebruikersnaam"},
        {"id":"Yes","value":"Ja"},    
        {"id":"Your phone is connected","value":"Uw telefoon is verbonden"}, 
        {"id":"Your organization","value":"Uw organisatie"}   
    ]
}