import React, { useState } from "react"
import PropTypes from 'prop-types'
import 'reactjs-popup/dist/index.css'

import { Translate } from "../Translator"

const TelephonySettingsVoipgrid = (props) => {

    //VoipGrid settings are always fixed
    return (
        <span>
            <table width="100%" cellPadding={4} className="smalltext">
            <tbody>
            <tr>
                <td>{Translate("Username")}</td>
                <td><span>{props.PhoneSettings["UserName"]}</span></td>
            </tr>
            <tr>
                <td>{Translate("Extension")}</td>
                <td><span>{props.PhoneSettings["DeviceNumber"]}</span></td>
            </tr>
            <tr>
                <td>{Translate("Auto Answer")}</td>
                <td><span>{props.PhoneSettings["AutoAnswer"] ? Translate("on") : Translate("off")}</span></td>
            </tr>
            <tr>
                <td>{Translate("Sip Account")}</td>
                <td><span>{props.PhoneSettings["SipAccount"]}</span></td>
            </tr>
            </tbody>
            </table>
        </span>
    )
}

TelephonySettingsVoipgrid.propTypes = {
    PhoneSettings: PropTypes.object,
    OnUpdatePhoneSettings: PropTypes.func
}

export default TelephonySettingsVoipgrid;