import React, { useState } from "react"
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/Button'
import 'reactjs-popup/dist/index.css'

import { Translate } from "../Translator"

const TelephonySettingsBroadsoft = (props) => {

    const mSettingsFixed = props.PhoneSettings["$Fixed"] === "y"
    const [mUsername, SetUsername] = useState(props.PhoneSettings["Username"]) 
    const [mPassword, SetPassword] = useState(props.PhoneSettings["Password"])  
    const [mBroadsoftUrl, SetBroadsoftUrl] = useState(props.PhoneSettings["BroadsoftUrl"]) 

    const UpdateSettings = () => {
        props.PhoneSettings["Username"] = mUsername
        props.PhoneSettings["Password"] = mPassword
        props.PhoneSettings["BroadsoftUrl"] = mBroadsoftUrl
        props.OnUpdatePhoneSettings()
    }    

    if (mSettingsFixed)
    {
        return (
            <span>
                <div className="smalltext">{Translate("Extension")}: {props.PhoneSettings["Extension"]}</div>
            </span>
        )
    }
    else
    {
        return (
            <span>
                <table width="100%" cellPadding={4} className="smalltext">
                <tbody>
                <tr>
                    <td>{Translate("Extension")}</td>
                    <td>{props.PhoneSettings["Extension"]}</td>
                </tr>
                <tr>
                    <td>{Translate("Username")}</td>
                    <td><input autoComplete="broadsoft.username" value={mUsername} type="text" onChange={(event) => { SetUsername(event.target.value) }} style={{ width: "300px"}}/></td>
                </tr>
                <tr>
                    <td>{Translate("Password")}</td>
                    <td><input autoComplete="broadsoft.password" value={mPassword} type="text" onChange={(event) => { SetPassword(event.target.value) }} style={{ width: "300px"}}/></td>
                </tr>
                <tr>
                    <td>{Translate("URL")}</td>
                    <td><input autoComplete="broadsoft.broadsofturl" value={mBroadsoftUrl} type="text" onChange={(event) => { SetBroadsoftUrl(event.target.value) }} style={{ width: "300px"}}/></td>
                </tr>
                </tbody>
                </table>
                {mUsername !== props.PhoneSettings["Username"] || mPassword !== props.PhoneSettings["Password"] || mBroadsoftUrl !== props.PhoneSettings["BroadsoftUrl"] ? <Button variant="primary" onClick={() => UpdateSettings()}>{Translate("Update settings")}</Button> : <Button variant="secondary" disabled>{Translate("Update settings")}</Button>}
            </span>
        )
    }
}

TelephonySettingsBroadsoft.propTypes = {
    PhoneSettings: PropTypes.object,
    OnUpdatePhoneSettings: PropTypes.func
}

export default TelephonySettingsBroadsoft