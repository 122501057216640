import axios from "axios"

const cPhoneBaseApi = "https://useraccount.cloudcti.nl/phonebeta/api/"

export async function PhoneApiMakeCall(aSecurityToken, aNumber) 
{
    //
}

export async function PhoneApiGetPhoneSettings(aSecurityToken) 
{
    try
    {
        return await axios.get(cPhoneBaseApi + "settings/phone",  
        {
            headers: {
                Authorization: "Bearer " + aSecurityToken,
                Accept: "application/json"
            }
        })
    }
    catch (ex)
    {
        return(ex)
    }
}

export async function PhoneApiSetPhoneSettings(aSecurityToken, aPhoneSettings) 
{
    return await axios.put(cPhoneBaseApi + "settings/phone",  
    {
        PhoneSettings: aPhoneSettings
    },
    {
        headers: {
            Authorization: "Bearer " + aSecurityToken,
            Accept: "application/json"
        }
    })
}

export async function PhoneApiSubscribe(aSecurityToken) 
{
    try
    {
        return await axios.post(cPhoneBaseApi + "subscription",   
        {
        },
        {
            headers: {
                Authorization: "Bearer " + aSecurityToken,
                Accept: "application/json"
            }
        })
    }
    catch (ex)
    {
        return(ex)
    }
}