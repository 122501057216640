import React, { useState } from "react"
import PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import Button from 'react-bootstrap/Button'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import ContactIcon from "./ContactIcon"

import { DarkBackground, OpenPopup } from "./GeneralFunctions"
import { ThemeBackgroundColor } from "./Themes"
import { Translate } from "./Translator"

import ActionCallSmallLightBgPng from '../Assets/action_call_small_lightbg.png'
import ActionCallSmallDarkBgPng from '../Assets/action_call_small_darkbg.png'
import ActionMultiplePng from '../Assets/action_multiple.png'

const SpeedDialItem = (props) => {

    const BackgroundColor = ThemeBackgroundColor()

    const [mMouseOver, SetMouseOver] = useState(false)
    const [mRepaint, SetRepaint] = useState(false)

    let CaptionLine2 = ""
    if (props.Organization && (props.Organization !== props.Name))
    {
        CaptionLine2 = props.Organization 
    }    

    return (
        <table className={props.OddRow ? "searchresult searchresultoddrow" : "searchresult"} width="100%" onMouseEnter={e => SetMouseOver(true)} onMouseLeave={e => SetMouseOver(false)}>
        <tbody>
            <tr>
                <td width="4"/>
                <td width="40" className="padded3">{<ContactIcon Application={props.Application} Name={props.Name} Number={props.Number}/>}</td>
                <td width="4"/>
                <td width="*" align="left" className="padded3">{props.Name ? props.Name : props.Number}
                    {CaptionLine2 ? <br/> : <span/>}
                    {CaptionLine2 ? <span>{CaptionLine2}</span> : <span/>}
                    {props.Name ? <br/> : <span/>}
                    {props.Name ? props.Number : <span/>}
                </td>
                <td width="10"/>
                {mMouseOver ? 
                    <td width="120" align="right">
                        <Popup trigger={<span className="up2px"><img alt="" src={ActionMultiplePng}  className="action"/></span>} arrow={false} closeOnDocumentClick={true} keepTooltipInside={true}>
                            <div onClick={() => props.OnMakeCall(props.Number, props.Name, props.Application, props.ScriptName, props.ScreenpopUrl)} className="popupmenuitem"><span className="popupmenuitemtext">{Translate("Call back")}</span></div>    
                            <div className="popupmenuitem"><CopyToClipboard text={props.Number}><span className="popupmenuitemtext">{Translate("Copy number")}</span></CopyToClipboard></div>
                            <div onClick={() => props.OnRemoveFromSpeedDial(props.Number)} className="popupmenuitem"><span className="popupmenuitemtext">{Translate("Remove from speed dial")}</span></div>

                            {props.Scripts === null ? <span/> : props.Scripts.map((script,index) => (                        
                                script.ScreenpopUrl ? <div onClick={() => OpenPopup(script.ScreenpopUrl)} className="popupmenuitem" key={index}><span className="popupmenuitemtext">{script.Name}</span></div> : <span/> 
                            ))} 
                        </Popup>

                        &nbsp;&nbsp;

                        <span className="up2px"><Button variant="light" className="callbutton action" onClick={() => props.OnMakeCall(props.Number, props.Name, props.Application, props.Scripts)}><img alt="" src={DarkBackground(BackgroundColor) ? ActionCallSmallDarkBgPng : ActionCallSmallLightBgPng} className="up5px"/>&nbsp; <span className="up3px">{Translate("Call")}</span></Button></span>
                    </td> : 
                    <td/>}
            </tr>
        </tbody>
        </table>
    )
}

SpeedDialItem.propTypes = {
    OddRow: PropTypes.bool,
    Number: PropTypes.string,
    Name: PropTypes.string,
    Organization: PropTypes.string,
    Application: PropTypes.string,
    Scripts: PropTypes.array,
    TenantGuid: PropTypes.string,
    OnMakeCall: PropTypes.func,
    OnRemoveFromSpeedDial: PropTypes.func
  }

export default SpeedDialItem;