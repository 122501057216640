import React, { useState } from "react"
import { Modal } from "react-bootstrap";
import PropTypes from 'prop-types'

import { GetTheme, SetTheme } from "./Themes"
import { Translate } from "./Translator"

import IntegrationPngLight from '../Assets/integration_lightbg.png'
import IntegrationPngDark from '../Assets/integration_darkbg.png'
import ActionPngLight from '../Assets/action_lightbg.png'
import ActionPngDark from '../Assets/action_darkbg.png'

//Telephony settings
import TelephonySettingsAmiba from './TelephonySettings/TelephonySettingsAmiba'
import TelephonySettingsAvaya from './TelephonySettings/TelephonySettingsAvaya'
import TelephonySettingsBroadsoft from './TelephonySettings/TelephonySettingsBroadsoft'
import TelephonySettingsSwyx from './TelephonySettings/TelephonySettingsSwyx'
import TelephonySettingsTapi from './TelephonySettings/TelephonySettingsTapi'
import TelephonySettingsTelepo from './TelephonySettings/TelephonySettingsTelepo'
import TelephonySettingsVoclarion from './TelephonySettings/TelephonySettingsVoclarion'
import TelephonySettingsVoipCenter from './TelephonySettings/TelephonySettingsVoipCenter'
import TelephonySettingsVoipgrid from './TelephonySettings/TelephonySettingsVoipgrid'
import TelephonySettingsXelion from './TelephonySettings/TelephonySettingsVoipgrid'

const UserSettingsModal = (props) => {

    const [mSelectedProtocol, SetSelectedProtocol] = useState("tel")  

    const RegisterProtocol = () => {
        const protocolURL = window.location.href + "/makecall?number=%s"
        navigator.registerProtocolHandler(mSelectedProtocol, protocolURL, "CloudCTI webclient")
    }

    const SetThemeEx = (theme) => {
        SetTheme(theme)
        window.location.reload()
    }

    const UpdatePhoneSettings = () => {
        props.OnUpdatePhoneSettings()
    }

    return (
        <Modal size="lg" scrollable={true} animation={false} show={true} onHide={() => props.OnHide()} className="scrollbary">
            <Modal.Header className="main" closeButton>
                <Modal.Title>{Translate("User settings")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="main">

                <table width="100%" cellPadding={16}>
                <tbody>
                <tr className="usersettings">
                    <td width="200" className="foregroundlightasbackground" valign="center">{Translate("Signed in as")}</td>
                    <td width="*" valign="center">{props.UserName + " (" + props.Email + ")"}</td>
                </tr>
                <tr className="usersettings">
                    <td width="200" className="foregroundlightasbackground" valign="center">{Translate("Your organization")}</td>
                    <td width="*" valign="center">{props.TenantName}</td>
                </tr>
                <tr className="usersettings">
                    <td width="200" className="foregroundlightasbackground" valign="center">{Translate("License")}</td>
                    <td width="*" valign="center">
                        {props.LicenceName}<br/>
                        <span className="smalltext">{Translate("Telephony platform")}: {props.LicencePlatforms}</span><br/>
                        <span className="smalltext">{Translate("Click-to-call")}: {Translate("yes")}</span><br/>
                        <span className="smalltext">{Translate("Caller recognition and scripts")}: {props.LicenceRecognitionAndScreenpop ? Translate("yes") : Translate("no")}</span>
                    </td>                                                                                 
                </tr>
                <tr className="usersettings">
                    <td width="200" className="foregroundlightasbackground" valign="center">{Translate("Language")}</td>
                    <td width="*" valign="center">
                    <select name="Language" style={{ paddingLeft: "2px", width: "120px" }} onChange={evt => props.OnUpdateLanguage(evt.target.value)} value={props.LanguageId === "nederlands" ? "nederlands" : ""}>
                        <option value="">English</option>
                        <option value="nederlands">Nederlands</option>
                    </select>        
                    </td>
                </tr>
                <tr className="usersettings">
                    <td width="200" className="foregroundlightasbackground" valign="center">{Translate("Theme")}</td>
                    <td width="*" valign="center">
                    <select name="Language" style={{ paddingLeft: "2px", width: "120px" }} onChange={evt => SetThemeEx(evt.target.value)} value={GetTheme() === "dark" ? "dark" : "light"}>
                        <option value="light">Light</option>
                        <option value="dark">Dark</option>
                    </select>      
                    </td>
                </tr>
                <tr className="usersettings" height="20"/>
                <tr className="usersettings">
                    <td width="200" className="foregroundlightasbackground" valign="center">{Translate("Telephony")}</td>
                    <td width="*" valign="center">
                        {props.PhoneApiError ? <div className="error">{props.PhoneApiError}</div> : <div className="ok">{Translate("Your phone is connected")}</div>}
                        {
                            props.PhoneSettingsTemplate === "Amiba" ? <TelephonySettingsAmiba MyConnect={false} PhoneSettings={props.PhoneSettings} OnUpdatePhoneSettings={() => UpdatePhoneSettings()}/> :
                            props.PhoneSettingsTemplate === "Avaya" ? <TelephonySettingsAvaya MyConnect={false} PhoneSettings={props.PhoneSettings} OnUpdatePhoneSettings={() => UpdatePhoneSettings()}/> :
                            props.PhoneSettingsTemplate === "Broadsoft2014" ? <TelephonySettingsBroadsoft MyConnect={false} PhoneSettings={props.PhoneSettings} OnUpdatePhoneSettings={() => UpdatePhoneSettings()}/> :
                            props.PhoneSettingsTemplate === "MyConnect" ? <TelephonySettingsTelepo MyConnect={true} PhoneSettings={props.PhoneSettings} OnUpdatePhoneSettings={() => UpdatePhoneSettings()}/> :
                            props.PhoneSettingsTemplate === "Swyx" ? <TelephonySettingsSwyx MyConnect={true} PhoneSettings={props.PhoneSettings} OnUpdatePhoneSettings={() => UpdatePhoneSettings()}/> :
                            props.PhoneSettingsTemplate === "Tapi" ? <TelephonySettingsTapi MyConnect={false} PhoneSettings={props.PhoneSettings} OnUpdatePhoneSettings={() => UpdatePhoneSettings()}/> :
                            props.PhoneSettingsTemplate === "Telepo" ? <TelephonySettingsTelepo MyConnect={false} PhoneSettings={props.PhoneSettings} OnUpdatePhoneSettings={() => UpdatePhoneSettings()}/> :
                            props.PhoneSettingsTemplate === "VoipCenter" ? <TelephonySettingsVoipCenter PhoneSettings={props.PhoneSettings} OnUpdatePhoneSettings={() => UpdatePhoneSettings()}/> :
                            props.PhoneSettingsTemplate === "VoipGrid" ? <TelephonySettingsVoipgrid PhoneSettings={props.PhoneSettings} OnUpdatePhoneSettings={() => UpdatePhoneSettings()}/> :
                            props.PhoneSettingsTemplate === "Voclarion" ? <TelephonySettingsVoclarion PhoneSettings={props.PhoneSettings} OnUpdatePhoneSettings={() => UpdatePhoneSettings()}/> :
                            props.PhoneSettingsTemplate === "Xelion" ? <TelephonySettingsXelion PhoneSettings={props.PhoneSettings} OnUpdatePhoneSettings={() => UpdatePhoneSettings()}/> :
                            <div>PBX settings not supported yet</div>
                        }
                    </td>
                </tr>
                <tr className="usersettings">
                    <td width="200" className="foregroundlightasbackground" valign="center">{Translate("Dialer URL Protocol")}</td>
                    <td width="*" valign="center">                       
                        <div className="hyperlink" onClick={() => RegisterProtocol()}>{Translate("Register tel:// protocol")}</div>
                    </td>
                </tr>
                <tr className="usersettings" height="20"/>
                <tr className="usersettings">
                    <td width="200" className="foregroundlightasbackground" valign="center">{Translate("CRMs and scripts")}</td>
                    <td width="*" valign="center">
                        {props.Integrations.map(
                            (integration,index) => <div key={index}><img src={GetTheme() === "dark" ? IntegrationPngDark : IntegrationPngLight} className="foregroundasbackground" width="24"/>{integration}</div>
                        )}
                        {props.CallFieldScripts.map(
                            (callfieldscript,index) => <div key={index}><img src={GetTheme() === "dark" ? ActionPngDark : ActionPngLight} className="foregroundasbackground" width="24"/>{callfieldscript}</div>
                        )}

                        {props.UserIsAdmin ? <br height="8"/> : <span/>}
                        {props.UserIsAdmin ? <div className="hyperlink" onClick={() => props.OnOpenWebConfigurator()}>{Translate("Click here to open the webconfigurator to configure your CRM")}</div> : <span/>}                      
                    </td>
                </tr>
                <tr className="usersettings">
                    <td width="200" className="foregroundlightasbackground" valign="center">{Translate("Browser notifications")}</td>
                    <td width="*" valign="center">
                        <div>Browser notifications are currently disabled</div>
                        <span className="hyperlink" onClick={() => props.OnEnableBrowserNotifications()}>{Translate("Enable")}</span>
                    </td>
                </tr>
                </tbody>
                </table>

            </Modal.Body>
        </Modal>
    )
}

UserSettingsModal.propTypes = {
    Email: PropTypes.string,
    UserName: PropTypes.string,
    TenantName: PropTypes.string,
    LicenceName: PropTypes.string,
    LicencePlatforms: PropTypes.string,
    LicenceRecognitionAndScreenpop: PropTypes.bool,
    LanguageId: PropTypes.string,
    Integrations: PropTypes.array,
    CallFieldScripts: PropTypes.array,
    UserIsAdmin: PropTypes.bool,
    PhoneApiError: PropTypes.string,
    PhoneSettingsTemplate: PropTypes.string,
    PhoneSettings: PropTypes.object,
    OnHide: PropTypes.func,
    OnUpdateLanguage: PropTypes.func,
    OnDisableBrowserNotifications: PropTypes.func,
    OnEnableBrowserNotifications: PropTypes.func,
    OnUpdatePhoneSettings: PropTypes.func,
    OnTranslate: PropTypes.func,
    OnOpenWebConfigurator: PropTypes.func
  }

export default UserSettingsModal