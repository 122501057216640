import React, { useState } from "react"
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'

import { MakeOsLocaleInfoLongDateTime } from "./GeneralFunctions"
import { Translate } from "./Translator"

const CallNotification = (props) => {
    

    const CallfieldScripts = [...props.Call.CallfieldScripts]
    const CallfieldScriptRows = []     
    while (CallfieldScripts.length) {
        CallfieldScriptRows.push(CallfieldScripts.splice(0, 2));
    }

    const ShowRecognition = (props.Call.Recognitions.length > 0)

    if (ShowRecognition)
    {
        const LookupResultIndex = props.LookupResultIndex < props.Call.Recognitions.length ? props.LookupResultIndex : (props.Call.Recognitions.length - 1)

        const RecognitionScripts = [...props.Call.Recognitions[LookupResultIndex].Scripts]
        RecognitionScripts.forEach((script) => script.CallfieldScript = false)
        const CallfieldScripts = [...props.Call.CallfieldScripts]
        CallfieldScripts.forEach((script) => script.CallfieldScript = true)

        const ScriptRows = []  
        const Scripts = [...RecognitionScripts, ...CallfieldScripts]   
        while (Scripts.length) { ScriptRows.push(Scripts.splice(0, 2)); }
      
        return (
            <span> 
                <b>{props.Call.Recognitions[LookupResultIndex].Notification[0]}</b><br/>
                {props.Call.Recognitions[LookupResultIndex].Notification.filter((line,index) => index > 0).map((line,index) => <div key={index}>{line}</div>)}
                <table cellPadding="5"><tbody>
                    {ScriptRows.map((row,rindex) => (
                        <tr key={rindex}>
                            {row.map((script,cindex) => (
                                <td width="50%" key={cindex}><Button className="scriptbutton" variant={script.CallfieldScript ? "outline-info" : "info"} title={script.Name}>{script.Name}</Button></td>
                            ))}
                        </tr>
                    ))}
                </tbody></table>
            </span>
        )
    }
    else
    {
        const CallfieldScripts = [...props.Call.CallfieldScripts]
        CallfieldScripts.forEach((script) => script.CallfieldScript = true)

        const ScriptRows = [] 
        const Scripts = [...CallfieldScripts]   
        while (Scripts.length) { ScriptRows.push(Scripts.splice(0, 2)); }

        return (
            <span> 
                <b>{Translate("Incoming call")}</b><br/>
                <div>{Translate("Number")}: {props.Call.CallerNumber}</div>
                {props.Call.DeviceNumber ? <div>{Translate("Device")}: {props.Call.DeviceNumber}</div> : <span/>}  
                <div>{Translate("Time")}: {MakeOsLocaleInfoLongDateTime(new Date())}</div>  
                <table cellPadding="5"><tbody>
                    {ScriptRows.map((row,rindex) => (
                        <tr key={rindex}>
                            {row.map((script,cindex) => (
                                <td width="10%" key={cindex}><Button className="scriptbutton" variant={script.CallfieldScript ? "outline-info" : "info"} title={script.Name}>{script.Name}</Button></td>
                            ))}
                        </tr>
                    ))}
                </tbody></table>               
            </span>
        )
    }
}

CallNotification.propTypes = {
    Call: PropTypes.object,
    LookupResultIndex: PropTypes.number
  }

export default CallNotification