import React, { useState } from "react"
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/Button'
import 'reactjs-popup/dist/index.css'

import { Translate } from "../Translator"

const TelephonySettingsTelepo = (props) => {

    const mSettingsFixed = props.PhoneSettings["$Fixed"] === "y"
    const [mUserId, SetUserId] = useState(props.PhoneSettings["UserId"])  
    const [mApiToken, SetApiToken] = useState(props.PhoneSettings["ApiToken"])  
    const [mPhoneType, SetPhoneType] = useState(props.PhoneSettings["PhoneType"])  
    const [mHostUrl, SetHostUrl] = useState(props.PhoneSettings["HostUrl"]) 

    const UpdateSettings = () => {
        props.PhoneSettings["UserId"] = mUserId
        props.PhoneSettings["ApiToken"] = mApiToken
        props.PhoneSettings["PhoneType"] = mPhoneType
        props.PhoneSettings["HostUrl"] = mHostUrl
        props.OnUpdatePhoneSettings()
    }    

    return (
        <span>
            <table width="100%" cellPadding={4} className="smalltext">
            <tbody>
            <tr>
                <td width="100">{Translate("User ID")}</td>
                <td width="*">{mSettingsFixed ? <span>{mUserId}</span> : <input autoComplete="userid" value={mUserId} type="text" onChange={(event) => { SetUserId(event.target.value) }} style={{ width: "300px"}}/>}</td>
            </tr>
            <tr>
                <td>{Translate("API token")}</td>
                <td>{mSettingsFixed ? <span>{mApiToken}</span> : <input autoComplete="apitoken" value={mApiToken} type="text" onChange={(event) => { SetApiToken(event.target.value) }} style={{ width: "300px"}}/>}</td>
            </tr>
            <tr>
                <td>{Translate("Phone type")}</td>
                <td>{mSettingsFixed ? 
                    (mPhoneType === "desktop" ? <span>{Translate("Desktop phone")}</span> :
                        mPhoneType === "mobile" ? <span>{Translate("Mobile phone")}</span> : 
                        mPhoneType === "soft" ? <span>{Translate("Softphone")}</span> : 
                        mPhoneType === "fxs" ? <span>{Translate("Analog phone")}</span> : 
                        mPhoneType === "external_pbx" ? <span>{Translate("External phone/PBX")}</span> : <span></span>) :
                    <select name="phonetype" style={{ paddingLeft: "2px", width: "300px" }} onChange={evt => SetPhoneType(evt.target.value)} value={mPhoneType}>
                        <option value="desktop">{Translate("Desktop phone")}</option>
                        <option value="mobile">{Translate("Mobile phone")}</option>
                        <option value="soft">{Translate("Softphone")}</option>
                        <option value="fxs">{Translate("Analog phone")}</option>
                        <option value="external_pbx">{Translate("External phone/PBX")}</option>
                    </select>}</td>
            </tr>
            <tr>
                <td>{Translate("Host URL")}</td>
                <td>{(mSettingsFixed || props.MyConnect) ? <span>{mHostUrl}</span> : <input autoComplete="hosturl" value={mHostUrl} type="text" onChange={(event) => { SetHostUrl(event.target.value) }} style={{ width: "300px"}}/>}</td>
            </tr>
            </tbody>
            </table>
            {mSettingsFixed ? <span/> : (mUserId !== props.PhoneSettings["UserId"] || mApiToken !== props.PhoneSettings["ApiToken"] || mPhoneType !== props.PhoneSettings["PhoneType"] || mHostUrl !== props.PhoneSettings["HostUrl"]? <Button variant="primary" onClick={() => UpdateSettings()}>{Translate("Update settings")}</Button> : <Button variant="secondary" disabled>{Translate("Update settings")}</Button>)}
        </span>
    )
}

TelephonySettingsTelepo.propTypes = {
    MyConnect: PropTypes.bool,
    PhoneSettings: PropTypes.object,
    OnUpdatePhoneSettings: PropTypes.func
}

export default TelephonySettingsTelepo