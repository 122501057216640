import React, { useState } from "react";
import PropTypes from 'prop-types';

import SpeedDialItem from "./SpeedDialItem"
import { MakeOsLocaleInfoShortDate } from "./GeneralFunctions"
import { Translate } from "./Translator"

const SpeedDials = (props) => {
  
    return (
      <table width="100%" cellPadding="0" cellSpacing="0">
      <tbody>
        {props.ShowHeader ? 
          <tr height="47" className="sectionheader">
            <td width="*" valign="center" align="left" className="sectionheader">
              <span className="up2px">{Translate("Speed dial")}</span>
            </td>
          </tr> 
          : <tr/>
        }  
        <tr height="*">
          <td colSpan="2" valign="top" align="left">
            <div className="scrollbary">
              {props.SpeedDials.map((speeddial,index) => (
                <div className="contactlistitem" key={index}>
                    <SpeedDialItem 
                        OddRow={index % 2 === 1}
                        Number={speeddial.Number} 
                        Name={speeddial.Name} 
                        Application={speeddial.Application} 
                        Scripts={speeddial.Scripts} 
                        OnMakeCall={(number, name, application, scripts) => props.OnMakeCall(number, name, application, scripts)} 
                        OnRemoveFromSpeedDial={(number) => props.OnRemoveFromSpeedDial(number)} 
                    />
                </div>
              ))} 
            </div>
          </td>
        </tr>              
      </tbody>
    </table>)
}

SpeedDials.propTypes = {
    SpeedDials: PropTypes.array,
    ShowHeader: PropTypes.bool,
    OnMakeCall: PropTypes.func,
    OnRemoveFromSpeedDial: PropTypes.func
}

export default SpeedDials