import React, { useState } from "react"
import PropTypes from 'prop-types'

const CallHistoryFilter = (props) => {

    return (
        <span className={props.Selected ? "historyfilterselected" : "historyfilter"} onClick={() => props.OnClick()}>{props.Caption}</span>
    )
}

CallHistoryFilter.propTypes = {
    Caption: PropTypes.string,
    Value: PropTypes.string,
    Selected: PropTypes.bool,
    OnClick: PropTypes.func
  }

export default CallHistoryFilter