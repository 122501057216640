export function DarkBackground(aBackgroundColor) 
{
    try
    {
        const colorString = aBackgroundColor.replace("#","")
        const red = parseInt(colorString.substring(0,2), 16)
        const green = parseInt(colorString.substring(2,4), 16)
        const blue = parseInt(colorString.substring(4,6), 16)
        const luma = 0.2126 * red + 0.7152 * green + 0.0722 * blue; // per ITU-R BT.709
        return (luma < 100) 
    }
    catch
    {
        return false
    }
}

export function UrlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
};

export function  MakeOsLocaleInfoShortDate(aDateTime) 
{
    try 
    {
        const NowDate = new Date()
        const ThenDate = new Date(aDateTime + " UTC")
        if ((NowDate.getFullYear() === ThenDate.getFullYear())
            && (NowDate.getMonth() === ThenDate.getMonth())
            && (NowDate.getDate() === ThenDate.getDate()))
        {
            return ThenDate.toLocaleTimeString()
        }
        else
        {
            return ThenDate.toLocaleDateString()
        }
    } 
    catch
    {
        return ""
    }
} 

export function NowInDateTimeFormat(aDateTime) 
{
    const NowDate = new Date()
    const Year = NowDate.getUTCFullYear()
    const Month = NowDate.getUTCMonth() + 1 //for whatever stupid reason, month is zero-based
    const Day = NowDate.getUTCDate()
    const Hours = NowDate.getUTCHours()
    const Minutes = NowDate.getUTCMinutes()
    const Seconds = NowDate.getUTCSeconds()
    
    return Year + "-" + 
        (Month < 10 ? "0" : "") + Month + "-" +
        (Day < 10 ? "0" : "") + Day + " " +
        (Hours < 10 ? "0" : "") + Hours + ":" +
        (Minutes < 10 ? "0" : "") + Minutes + ":" +
        (Seconds < 10 ? "0" : "") + Seconds
} 

export function  MakeOsLocaleInfoLongDateTime(aDateTime) 
{
    return aDateTime.toLocaleDateString() + " " + aDateTime.toLocaleTimeString()
} 

export function OpenPopup(aUrl)  
{
    window.open(aUrl, "_blank")
}

export function IsEmptyString(aString)  
{
    return aString === undefined
        || aString === null
        || !aString
}