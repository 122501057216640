import React, { useState } from "react"
import PropTypes from 'prop-types'
import 'reactjs-popup/dist/index.css'

import { Translate } from "../Translator"

//VoIPCenter does not allow changing settings in the Phone API

const TelephonySettingsAvaya = (props) => {

    return (
        <span>
            <div className="smalltext">{Translate("Extension")}: {props.PhoneSettings["DeviceNumber"]}</div>
        </span>
    )
}

TelephonySettingsAvaya.propTypes = {
    PhoneSettings: PropTypes.object,
    OnUpdatePhoneSettings: PropTypes.func
}

export default TelephonySettingsAvaya