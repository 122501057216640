import React, { useState } from "react"
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'

import SearchField from "./SearchField"
import SearchResult from "./SearchResult"
import DialpadModal from "./DialpadModal"

import { LookupApiSearchName, LookupApiGetSearchHistory, LookupApiUpdateSearchHistory } from "../ExternalApi/LookupApi"
import { Translate } from "./Translator"

import DialPadPng from '../Assets/dialpad.png'

const SearchArea = (props) => {

  const [mCurrentSearchError, SetCurrentSearchError] = useState("")
  const [mCurrentSearchText, SetCurrentSearchText] = useState("")
  const [mCurrentSearchResults, SetCurrentSearchResults] = useState([])
  const [mCurrentSearchResultsMore, SetCurrentSearchResultsMore] = useState(false)

  const [mShowModalDialpad, SetShowModalDialpad] = useState(false)

  const MakeCallAndAddToSearchHistory = (aNumber, aName, aApplication, aScripts) => {
    props.OnMakeCall(aNumber, aName, aApplication, aScripts)
    props.OnAddToSearchHistory(aNumber, aName, aApplication, aScripts)
  }

  const SearchName = async (value) => {
      try 
      {
          SetCurrentSearchResults(null)
          SetCurrentSearchResultsMore(false)
          SetCurrentSearchText(value)

          if ((value.length > 0) && (value.length < 3))
          {
            SetCurrentSearchError(Translate("Search field must contain at least 3 characters"))
            return
          }
          else if (value.length > 2)
          {
            const searchResult = await LookupApiSearchName(props.SecurityToken, value)

            if (searchResult)
            {
              SetCurrentSearchResults(searchResult.Matches)
              SetCurrentSearchResultsMore(searchResult.MoreMatches)
              SetCurrentSearchError("")
            }
            else
            {
              SetCurrentSearchResults([])
              SetCurrentSearchResultsMore(false)
              SetCurrentSearchError(Translate("An error has occurred while searching"))           
            }
          }
      } 
      catch (ex) 
      {    
          SetCurrentSearchResults([])

          let error = ""
          try { error = ex.response.data.Exception; } catch {}
          if (!error) { try { error = ex.response.data } catch {} }
          SetCurrentSearchError(error ? error : Translate("An error has occurred while searching"))
      }
  }
  
  return <table width="100%" height="100%" cellPadding="0" cellSpacing="0">
      <tbody>
        <tr height="25">
          <td width="*" style={{ paddingLeft: "6px"}}><SearchField OnChanged={() => SetCurrentSearchError("")} OnSearchName={(name) => SearchName(name)} /></td>
          <td width="30" style={{ paddingRight: "2px"}} align="right"><Button variant="outline-info" className="primarybutton link" title="Dial number" onClick={() => SetShowModalDialpad(true)}><img src={DialPadPng}/></Button></td>
        </tr>
        <tr height="100%">
          <td colSpan="2" valign="top" align="left"> 
            <div className="scrollbary">

              {mCurrentSearchError ? <div className="contactlistitem normalcell">{mCurrentSearchError}</div> :
                (mCurrentSearchText ? 
                  mCurrentSearchResults === null ?
                  <div className="contactlistitem normalcell">{Translate("Searching")}...</div> :

                  mCurrentSearchResults.length ? (
                      mCurrentSearchResults.map((currentSearch,index) => (
                        <div className="contactlistitem" key={index}><SearchResult OddRow={index % 2 === 1} Number={currentSearch.Number} Name={currentSearch.Name} Organization={currentSearch.Organization} Application={currentSearch.Application} Scripts={currentSearch.Scripts} TenantGuid={props.TenantGuid} OnMakeCall={(number, name, application, scripts) => MakeCallAndAddToSearchHistory(number, name, application, scripts)}/></div>
                      ))
                  ) : 
                  <div className="contactlistitem normalcell">{Translate("No results found")}</div>
                :
                ((props.SearchHistory === null) || (props.SearchHistory === undefined) || (props.SearchHistory.length === 0) ? <span/> :
                props.SearchHistory.map((searchHistoryItem,index) => (
                    <div className="contactlistitem" key={index}><SearchResult OddRow={index % 2 === 1} Number={searchHistoryItem.Number} Name={searchHistoryItem.Name} Organization={searchHistoryItem.Organization} Application={searchHistoryItem.Application} Scripts={searchHistoryItem.Scripts} TenantGuid={props.TenantGuid} OnMakeCall={(number, name, application, scripts) => props.OnMakeCall(number, name, application, scripts)}/></div>
                  ))))              
              }

              {mCurrentSearchResultsMore ? <span><div align="center" className="smalltext normalcell"><br/>{Translate("More results found. Refine your search")}</div><br/></span> : <span/>}
            
              {mShowModalDialpad ? <DialpadModal OnDial={(number) => props.OnMakeCall(number, null, null, null) } OnHide={() => SetShowModalDialpad(false)} OnTranslate={Translate}/> : <span/>}
            </div>       
          </td>
        </tr>
      </tbody>
    </table>
}

SearchArea.propTypes = {
  SecurityToken: PropTypes.string,
  SearchHistory: PropTypes.array,
  OnMakeCall: PropTypes.func,
  OnAddToSearchHistory: PropTypes.func
}

export default SearchArea