import React, { useState } from "react"
import PropTypes from 'prop-types'
import 'reactjs-popup/dist/index.css'

import { Translate } from "../Translator"

const TelephonySettingsVoclarion = (props) => {

    const mSettingsFixed = props.PhoneSettings["$Fixed"] === "y"
    const [mUserName, SetUserName] = useState(props.PhoneSettings["UserName"])  
    const [mPassword, SetPassword] = useState(props.PhoneSettings["Password"])  
    const [mAstSerial, SetAstSerial] = useState(props.PhoneSettings["AstSerial"]) 

    const UpdateSettings = () => {
        props.PhoneSettings["UserName"] = mUserName
        props.PhoneSettings["Password"] = mPassword
        props.PhoneSettings["AstSerial"] = mAstSerial
        props.OnUpdatePhoneSettings()
    }  

    return (
        <span>
            <table width="100%" cellPadding={4} className="smalltext">
            <tbody>
            <tr>
                <td width="100">{Translate("Username")}</td>
                <td width="*">{mSettingsFixed ? <span>{mUserName}</span> : <input autoComplete="username" value={mUserName} type="text" onChange={(event) => { SetUserName(event.target.value) }} style={{ width: "300px"}}/>}</td>
            </tr>
            <tr>
                <td>{Translate("Password")}</td>
                <td>{mSettingsFixed ? <span>{mPassword}</span> : <input autoComplete="password" value={mPassword} type="text" onChange={(event) => { SetPassword(event.target.value) }} style={{ width: "300px"}}/>}</td>
            </tr>
            <tr>
                <td>{Translate("Asterisk serial")}</td>
                <td>{mSettingsFixed ? <span>{mAstSerial}</span> : <input autoComplete="hosturl" value={mAstSerial} type="text" onChange={(event) => { SetAstSerial(event.target.value) }} style={{ width: "300px"}}/>}</td>
            </tr>
            </tbody>
            </table>
            {mSettingsFixed ? <span/> : (mUserName !== props.PhoneSettings["UserName"] || mPassword !== props.PhoneSettings["Password"] || mAstSerial !== props.PhoneSettings["AstSerial"]? <Button variant="primary" onClick={() => UpdateSettings()}>{Translate("Update settings")}</Button> : <Button variant="secondary" disabled>{Translate("Update settings")}</Button>)}
        </span>
    )
}

TelephonySettingsVoclarion.propTypes = {
    PhoneSettings: PropTypes.object,
    OnUpdatePhoneSettings: PropTypes.func
}

export default TelephonySettingsVoclarion;