import React from "react"
import PropTypes from 'prop-types'
import 'reactjs-popup/dist/index.css'

import PhonePng from '../Assets/phone.png'

const ContactIcon = (props) => {

    return (
        props.Application ? <img alt="" src={"https://downloadcloudcti.blob.core.windows.net/files/WebRCT/Applications/" + props.Application + ".png"} className="applicationpng" width="32" height="32"/> :
            (props.Name ? <table width="32" height="32" className="unknowncrm"><tbody><tr><td align="center" valign="center">{props.Name[0]}</td></tr></tbody></table> :
                <table width="32" height="32" className="unknowncrm"><tbody><tr><td align="center" valign="center"><img alt="" src={PhonePng} width="15" height="15"/></td></tr></tbody></table>   
            )
    )    
}

ContactIcon.propTypes = {
    Application: PropTypes.string,
    Name: PropTypes.string,
    Number: PropTypes.string
  }

export default ContactIcon;