import React, { useState } from "react"
import { Modal } from "react-bootstrap";
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'

import BackspacePng from '../Assets/backspace.png';

const DialpadModal = (props) => {

    const [mNumber, SetNumber] = useState("")

    return (
        <Modal size="sm" animation={false} show={true} onHide={() => props.OnHide()}>
            <Modal.Header className="main" closeButton>
                <Modal.Title>{props.OnTranslate("Dial number")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="main">

                <table width="100%" cellPadding={3}>
                <tbody>
                <tr>
                    <td width="120" align="center" valign="center">
                        <div className="dialpadbutton" onClick={() => SetNumber(mNumber + "1")}>
                            <span className="dialpadbigtext">1</span><br/>
                            <span className="smalltext">&nbsp;</span>
                        </div>
                    </td>
                    <td width="*"/>
                    <td width="120" align="center" valign="center">
                        <div className="dialpadbutton" onClick={() => SetNumber(mNumber + "2")}>
                            <span className="dialpadbigtext">2</span><br/>
                            <span className="smalltext">ABC</span>
                        </div>
                    </td>
                    <td width="*"/>
                    <td width="120" align="center" valign="center">
                        <div className="dialpadbutton" onClick={() => SetNumber(mNumber + "3")}>
                            <span className="dialpadbigtext">3</span><br/>
                            <span className="smalltext">DEF</span>
                        </div>
                    </td>
                </tr>
                <tr height="6"/>
                <tr>
                    <td width="120" align="center" valign="center">
                        <div className="dialpadbutton" onClick={() => SetNumber(mNumber + "4")}>
                            <span className="dialpadbigtext">4</span><br/>
                            <span className="smalltext">GHI</span>
                        </div>
                    </td>
                    <td width="*"/>
                    <td width="120" align="center" valign="center">
                        <div className="dialpadbutton" onClick={() => SetNumber(mNumber + "5")}>
                            <span className="dialpadbigtext">5</span><br/>
                            <span className="smalltext">JKL</span>
                        </div>
                    </td>
                    <td width="*"/>
                    <td width="120" align="center" valign="center">
                        <div className="dialpadbutton" onClick={() => SetNumber(mNumber + "6")}>
                            <span className="dialpadbigtext">6</span><br/>
                            <span className="smalltext">MNO</span>
                        </div>
                    </td>
                </tr>
                <tr height="6"/>
                <tr>
                    <td width="120" align="center" valign="center">
                        <div className="dialpadbutton" onClick={() => SetNumber(mNumber + "7")}>
                            <span className="dialpadbigtext">7</span><br/>
                            <span className="smalltext">PQRS</span>
                        </div>
                    </td>
                    <td width="*"/>
                    <td width="120" align="center" valign="center">
                        <div className="dialpadbutton" onClick={() => SetNumber(mNumber + "8")}>
                            <span className="dialpadbigtext">8</span><br/>
                            <span className="smalltext">TUV</span>
                        </div>
                    </td>
                    <td width="*"/>
                    <td width="120" align="center" valign="center">
                        <div className="dialpadbutton" onClick={() => SetNumber(mNumber + "9")}>
                            <span className="dialpadbigtext">9</span><br/>
                            <span className="smalltext">WXYZ</span>
                        </div>
                    </td>
                </tr>
                <tr height="6"/>
                <tr>
                    <td width="120" align="center" valign="center">
                        <div className="dialpadbutton" onClick={() => SetNumber(mNumber + "*")}>
                            <span className="dialpadbigtext">*</span><br/>
                            <span className="smalltext">&nbsp;</span>
                        </div>
                    </td>
                    <td width="*"/>
                    <td width="120" align="center" valign="center">
                        <div className="dialpadbutton" onClick={() => SetNumber(mNumber + "0")}>
                            <span className="dialpadbigtext">0</span><br/>
                            <span className="smalltext">+</span>
                        </div>
                    </td>
                    <td width="*"/>
                    <td width="120" align="center" valign="center">
                        <div className="dialpadbutton" onClick={() => SetNumber(mNumber + "#")}>
                            <span className="dialpadbigtext">#</span><br/>
                            <span className="smalltext">&nbsp;</span>
                        </div>
                    </td>
                </tr>
                <tr height="6"/>
                <tr>
                    <td colSpan="5" align="left" valign="center">
                        <input type="text" placeholder="Phone number" className="dialpadinput" value={mNumber} onChange={evt => SetNumber(evt.target.value)} autoFocus />
                        <Button variant="outline-info" className="primarybutton dialpadinputbackspace link" title="Remove last digit" onClick={() => SetNumber(mNumber.slice(0,-1))}><img src={BackspacePng}/></Button>
                    </td>
                </tr>
                <tr height="6"/>
                <tr>
                    <td colSpan="5" align="left" valign="center">
                        <Button variant="primary" className="dialpadcallbutton link" title="Dial" onClick={() => { props.OnDial(mNumber), props.OnHide() }}>Dial</Button>
                    </td>
                </tr>
                </tbody>
                </table>

            </Modal.Body>
        </Modal>
    )
}

DialpadModal.propTypes = {
    OnDial: PropTypes.func,
    OnHide: PropTypes.func,
    OnTranslate: PropTypes.func
  }

export default DialpadModal