let mTheme = localStorage.getItem("Theme")

const LightModeFontColor = "#000000"
const LightModeBackgroundColor = "#FFFFFF"
const LightModeBackgroundColorSlightlyOff = "#F6F6F6"
const LightModeBorderColor = "#EEEEEE"
const LightModeHistoryBackgroundColor = LightModeBackgroundColor
const LightModeHistoryBackgroundColorHover = "#E0E0E0"
const LightModeHistoryBackgroundColorFocus = "#E8EBFA"
const LightModeHistoryBackgroundColorHoverFocus = "#9299F7"
const LightModeHistoryFontColorFocus = "#8F52B9"

const DarkModeFontColor = "#FFFFFF"
const DarkModeBackgroundColor = "#000000"
const DarkModeBackgroundColorSlightlyOff = "#161616"
const DarkModeBorderColor = "#292929"
const DarkModeHistoryBackgroundColor = DarkModeBackgroundColor
const DarkModeHistoryBackgroundColorHover = DarkModeBackgroundColor
const DarkModeHistoryBackgroundColorFocus = "#2F2F4A"
const DarkModeHistoryBackgroundColorHoverFocus = "#4F52B2"
const DarkModeHistoryFontColorFocus = "#7F85F5"

export function GetTheme()
{
  return mTheme
}

export function SetTheme(aTheme)
{
  mTheme = aTheme
  localStorage.setItem("Theme",aTheme)
}


export function ThemeBackgroundColor() 
{
    return mTheme === "dark" ? DarkModeBackgroundColor : LightModeBackgroundColor
}

export function ThemeBorderColor() 
{
    return mTheme === "dark" ? DarkModeBorderColor : LightModeBorderColor
}

export function ThemeFontColor() 
{
    return mTheme === "dark" ? DarkModeFontColor : LightModeFontColor
}

export function ThemeHistoryBackgroundColor() 
{
    return mTheme === "dark" ? DarkModeHistoryBackgroundColor : LightModeHistoryBackgroundColor
}

export function ThemeHistoryBackgroundColorHover() 
{
    return mTheme === "dark" ? DarkModeHistoryBackgroundColorHover : LightModeHistoryBackgroundColorHover
}

export function ThemeBackgroundColorSlightlyOff() 
{
    return mTheme === "dark" ? DarkModeBackgroundColorSlightlyOff : LightModeBackgroundColorSlightlyOff
}

export function ThemeHistoryBackgroundColorFocus() 
{
    return mTheme === "dark" ? DarkModeHistoryBackgroundColorFocus : LightModeHistoryBackgroundColorFocus
}

export function ThemeHistoryBackgroundColorHoverFocus() 
{
    return mTheme === "dark" ? DarkModeHistoryBackgroundColorHoverFocus : LightModeHistoryBackgroundColorHoverFocus
}

export function ThemeHistoryFontColorFocus() 
{
    return mTheme === "dark" ? DarkModeHistoryFontColorFocus : LightModeHistoryFontColorFocus
}